async function downloadFile(url: string, fileName?: string) {
  // TODO
  // https://stackoverflow.com/questions/39963788/images-from-s3-not-working-with-service-worker
  // https://stackoverflow.com/questions/39109789/what-limitations-apply-to-opaque-responses
  // https://stackoverflow.com/questions/14176470/canvas-element-with-and-image-loaded-from-aws-s3-using-cors-does-not-work-first
  let pFileName = fileName ?? url;
  try {
    if (!fileName || fileName === "") {
      const extractedFilenameWithPars = url.substr(url.lastIndexOf("/") + 1);
      const extractedFilename = extractedFilenameWithPars.split("?")[0];
      pFileName = extractedFilename;
    }
  } catch (e) {
    console.error("Couldn't get exact filename!");
  }

  await fetch(url, {
    method: "get",
    referrerPolicy: "no-referrer",
    headers: { origin: window.location.href },
  })
    .then((res) => res.blob())
    .then((res) => {
      const aElement = document.createElement("a");
      aElement.setAttribute("download", pFileName);
      aElement.setAttribute("crossorigin", window.location.href);
      const href = URL.createObjectURL(res);
      aElement.setAttribute("href", href);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
}

export default downloadFile;
