/* eslint-disable import/prefer-default-export */
import { ImporterLocale } from "./ImporterLocale";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const esES: ImporterLocale = {
  general: {
    goToPreviousStepTooltip: "Atrás",
  },
  fileStep: {
    initialDragDropPrompt:
      "Arrastre el archivo CSV aquí, o haga clic para seleccionarlo desde su ordenador",
    activeDragDropPrompt: "Libere el archivo CSV aquí...",

    getImportError: (message) => `Error durante la importación: ${message}`,
    getDataFormatError: (message) => `Compruebe el formato de los datos: ${message}`,
    goBackButton: "Atrás",
    nextButton: "Seleccionar columnas",

    rawFileContentsHeading: "Contenido del archivo cargado",
    previewImportHeading: "Vista previa de datos",
    dataHasHeadersCheckbox: "Encabezado presente en el archivo",
    previewLoadingStatus: "Cargando vista previa...",
  },
  fieldsStep: {
    stepSubtitle: "Seleccionar columnas",
    requiredFieldsError: "Asigne todas las columnas requeridas",
    nextButton: "Importar",

    dragSourceAreaCaption: "Columnas a importar",
    getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
      `Página ${currentPage} de ${pageCount}`,
    getDragSourceActiveStatus: (columnCode: string) => `Asignación a la columna ${columnCode}`,
    nextColumnsTooltip: "Mostrar columna siguiente",
    previousColumnsTooltip: "Mostrar columna anterior",
    clearAssignmentTooltip: "Borrar todas las asignaciones de columnas",
    selectColumnTooltip: "Seleccione una columna para asignar",
    unselectColumnTooltip: "Deseleccionar columna",

    dragTargetAreaCaption: "Campos requeridos",
    getDragTargetOptionalCaption: (field) => `${field} (opcional)`,
    getDragTargetRequiredCaption: (field) => `${field} (obligatorio)`,
    dragTargetPlaceholder: "Arrastre la columna aquí",
    getDragTargetAssignTooltip: (columnCode: string) => `Asignación a la columna ${columnCode}`,
    dragTargetClearTooltip: "Borrar asignaciones de columnas",

    columnCardDummyHeader: "Campo no asignado",
    getColumnCardHeader: (code) => `Column ${code}`,
  },

  progressStep: {
    stepSubtitle: "Importar",
    uploadMoreButton: "Cargar más datos",
    finishButton: "Fin",
    statusError: "Error de carga",
    statusComplete: "Completado",
    statusPending: "Cargando...",
    processedRowsLabel: "Filas procesadas:",
  },
};
