import i18n from "i18n/i18n";

const MODE_OPTIONS = [
  {
    label: i18n?.t("Zoom mode"),
    value: "zoomMode",
  },
  {
    label: i18n?.t("Draw mode"),
    value: "drawMode",
  },
  {
    label: i18n?.t("Text mode"),
    value: "textMode",
  },
];

const COLOR_OPTIONS = [
  {
    label: i18n?.t("Red"),
    value: "red",
  },
  {
    label: i18n?.t("Yellow"),
    value: "yellow",
  },
  {
    label: i18n?.t("Green"),
    value: "green",
  },
  {
    label: i18n?.t("Blue"),
    value: "blue",
  },
  {
    label: i18n?.t("White"),
    value: "white",
  },
  {
    label: i18n?.t("Black"),
    value: "black",
  },
];

const FONT_SIZE_OPTIONS = [
  {
    label: i18n?.t("normal"),
    value: "normal",
  },
  {
    label: i18n?.t("bolder"),
    value: "bolder",
  },
];

export { MODE_OPTIONS, COLOR_OPTIONS, FONT_SIZE_OPTIONS };
