import { PolicyAssetInputExtended } from "pages/spin/types";

const calculateConvertedTotalInsuredValue = (
  policyAssets: PolicyAssetInputExtended[],
  currency: string,
  exchangeRates: {
    baseCurrency: string;
    currency: string;
    value?: number;
  }[]
) => {
  if (!policyAssets) return 0;
  return policyAssets
    .reduce((accumulator, { insuredValue }) => {
      if (!Number.isNaN(insuredValue.amount)) {
        let value = Number(insuredValue.amount);
        const valueNeedsConversion = insuredValue?.currency !== currency;

        if (valueNeedsConversion) {
          const currencyConversion = exchangeRates?.find(
            ({ baseCurrency }) => baseCurrency === insuredValue?.currency
          );

          value *= currencyConversion ? Number(currencyConversion?.value) : 1;
        }
        return accumulator + value;
      }
      return accumulator;
    }, 0)
    .toFixed(2);
};

export default calculateConvertedTotalInsuredValue;
