export const IMPORT_FIELDS = [
  { name: "title", optional: false },
  { name: "author", optional: true },
  { name: "creationPeriod", optional: true },
  { name: "technique", optional: true },
  { name: "dimensionsNotes", optional: true },
  { name: "netHeight", optional: true },
  { name: "netWidth", optional: true },
  { name: "netDepth", optional: true },
  { name: "netLengthUnit", optional: true },
  { name: "inventoryNumber", optional: true },
  { name: "assetPresentValueAmount", optional: true },
  { name: "assetPresentValueCurrency", optional: true },
];
