import { saveAs } from "file-saver";
import { Node } from "graphql/queries/generated/queries";
import i18n from "i18n/i18n";
// eslint-disable-next-line import/no-extraneous-dependencies
import JSZip from "jszip";
import { toast } from "middleware/Toaster";
// function getUrlExtension(url: string) {
//   return url.split(/[#?]/)[0].split(".").pop().trim();
// }
function zipAndDownload<T extends Node & { file: string }>(node: Partial<T>[]) {
  const zip = new JSZip();

  const sourceData = node.map(async (node) => {
    const fileUrl = node.file;
    // console.log({ fileUrl });
    const response = await fetch(fileUrl, {
      method: "get",
      referrerPolicy: "no-referrer",
      headers: { origin: window.location.href },
    });
    const data = await response.blob();
    const lastIndexOfSlash = fileUrl.lastIndexOf("/");
    const lastIndexOfFullstop = fileUrl.lastIndexOf(".");
    // const firstQuestionMark = fileUrl.indexOf("?");
    const format = fileUrl.split(/[#?]/)[0].split(".").pop().trim();
    // const format = fileUrl.substring(lastIndexOfFullstop + 1, firstQuestionMark);
    const fileName = fileUrl.substring(lastIndexOfSlash + 1, lastIndexOfFullstop);
    zip.file(`${fileName}.${format}`, data);
    return data;
  });
  Promise.all(sourceData)
    .then(async () => {
      await zip.generateAsync({ type: "blob" }).then((content) => {
        // see FileSaver.js
        // console.log({ content });
        saveAs(content, `certificates_export.zip`);
      });
    })
    .catch((e) => {
      toast({
        title: i18n.t("Notification.download.failed_could_not_download_files"),
        status: "error",
      });
      console.error(e);
    });
}

export default zipAndDownload;
