import { ApolloLink, Observable } from "apollo-link";

import {
  selectURI,
  selectHttpOptionsAndBody,
  fallbackHttpConfig,
  serializeFetchParameter,
  createSignalIfSupported,
  parseAndCheckHttpResponse,
} from "@apollo/client";
import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";
import { SHOW_REQUESTS } from "./constants";
export const createUploadLink = ({
  uri: fetchUri = "/graphql",
  fetch: linkFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions,
} = {}) => {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers,
  };

  return new ApolloLink((operation) => {
    const uri = selectURI(operation, fetchUri);
    const context = operation.getContext();
    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      linkConfig,
      contextConfig
    );

    const { files } = extractFiles(body, isExtractableFile);
    const payload = serializeFetchParameter(body, "Payload"); // Original

    // console.log({ files, payload }, files.length, files.size);
    if (files.size) {
      // Automatically set by fetch when the body is a FormData instance.
      delete options.headers["content-type"];

      // GraphQL multipart request spec:
      // https://github.com/jaydenseric/graphql-multipart-request-spec
      options.body = new FormData();
      const jsonPayload = JSON.parse(payload);
      const query = JSON.stringify(jsonPayload.query);
      options.body.append(
        "query",
        query.replace(/\\n/g, " ").replace(/\\r/g, " ").replace(/"/, "").replace(/"$/, "")
      );

      delete jsonPayload.variables.files;
      options.body.append("variables", JSON.stringify(jsonPayload.variables));
      files.forEach((value, key) => {
        SHOW_REQUESTS && console.log("[FILEUPLOAD] Debug", value, key);
        options.body.append("files", key, key.name);
      });
    } else options.body = payload;
    SHOW_REQUESTS && console.log("[FILEUPLOAD] Debug", { files, payload, options });

    return new Observable((observer) => {
      // Allow aborting fetch, if supported.
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;

      linkFetch(uri, options)
        .then((response) => {
          // Forward the response on the context.
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => {
          if (error.name === "AbortError")
            // Fetch was aborted.
            return;

          if (error.result && error.result.errors && error.result.data) {
            // There is a GraphQL result to forward.
            observer.next(error.result);
          }

          observer.error(error);
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  });
};
