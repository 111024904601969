/* eslint-disable import/prefer-default-export */
import { ImporterLocale } from "./ImporterLocale";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const frFR: ImporterLocale = {
  general: {
    goToPreviousStepTooltip: "Retour",
  },
  fileStep: {
    initialDragDropPrompt:
      "Faites glisser le fichier CSV ici, ou cliquez pour le sélectionner sur votre ordinateur",
    activeDragDropPrompt: "Téléchargez le fichier CSV ici...",

    getImportError: (message) => `Erreur lors de l'importation: ${message}`,
    getDataFormatError: (message) => `Vérifier le format des données: ${message}`,
    goBackButton: "Retour",
    nextButton: "Sélectionner les colonnes",

    rawFileContentsHeading: "Contenu du fichier téléchargé",
    previewImportHeading: "Aperçu des données",
    dataHasHeadersCheckbox: "En-tête présent dans le fichier",
    previewLoadingStatus: "Chargement de l'aperçu...",
  },
  fieldsStep: {
    stepSubtitle: "Sélectionner les colonnes",
    requiredFieldsError: "Veuillez attribuer toutes les colonnes nécessaires",
    nextButton: "Importer",

    dragSourceAreaCaption: "Colonnes à importer",
    getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
      `Page ${currentPage} de ${pageCount}`,
    getDragSourceActiveStatus: (columnCode: string) => `Affectation à la colonne ${columnCode}`,
    nextColumnsTooltip: "Afficher la colonne suivante",
    previousColumnsTooltip: "Afficher la colonne précédente",
    clearAssignmentTooltip: "Supprimer toutes les affectations de colonnes",
    selectColumnTooltip: "Sélectionner une colonne à affecter",
    unselectColumnTooltip: "Désélectionner la colonne",

    dragTargetAreaCaption: "Champs obligatoires",
    getDragTargetOptionalCaption: (field) => `${field} (facultatif)`,
    getDragTargetRequiredCaption: (field) => `${field} (obligatoire)`,
    dragTargetPlaceholder: "Faites glisser la colonne ici",
    getDragTargetAssignTooltip: (columnCode: string) => `Affectation à la colonne ${columnCode}`,
    dragTargetClearTooltip: "Supprimer les affectations de colonnes",

    columnCardDummyHeader: "Champ non attribué",
    getColumnCardHeader: (code) => `Column ${code}`,
  },

  progressStep: {
    stepSubtitle: "Importer",
    uploadMoreButton: "Téléchargement de données supplémentaires",
    finishButton: "Fin",
    statusError: "Erreur de chargement",
    statusComplete: "Terminé",
    statusPending: "Chargement...",
    processedRowsLabel: "Lignes traitées:",
  },
};
