// eslint-disable

import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "apollo-link-error";
import merge from "deepmerge";
import { useMemo } from "react";
import { createUploadLink } from "./apollo-upload-link";
import { makeTokenRefreshLink } from "./apollo-token-refresh-link";

let apolloClient;

const wait = (ms) => new Promise((res) => setTimeout(res, ms));
const delayRefetchedQuery = async (observableQuery) => {
  await wait(import.meta.env.VITE_REFETCH_WAIT_TIME ?? "1000"); // 3s to make it super obvious if working or not
  observableQuery.refetch();
};

function getHeaders() {
  const headers = {};

  // bug: could be that we need to parse it with json.parse
  const token = sessionStorage.getItem("jwtToken");
  const language = localStorage.getItem("i18nextLng");

  if (token) headers.Authorization = `JWT ${token}`;
  if (language) headers["Accept-Language"] = language;
  return headers;
}

function operationIsSubscription(operation) {
  const definition = getMainDefinition(operation.query);
  const isSubscription =
    definition.kind === "OperationDefinition" && definition.operation === "subscription";
  return isSubscription;
}

let wsLink;
function getOrCreateWebsocketLink() {
  wsLink ??= new WebSocketLink({
    uri: import.meta.env.NEXT_PUBLIC_WS_ENDPOINT.replace("http", "ws").replace("https", "wss"),
    options: {
      reconnect: true,
      timeout: 30000,
      connectionParams: () => ({ headers: getHeaders() }),
    },
  });
  return wsLink;
}

function createLink() {
  const protocol = import.meta.env.PROD ? "https://" : "http://";
  const url = `${import.meta.env.VITE_GRAPHQL_ENDPOINT}`;

  const httpLink = createUploadLink({
    uri: `${protocol}${url}`,
    credentials: "include",
  });
  // const httpLink = new HttpLink({
  //   uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  //   credentials: "include",
  // });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...getHeaders(),
        "X-Tenant": import.meta.env.VITE_TENANT_ENDPOINT,
      },
    }));
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const tokenRefreshLink = makeTokenRefreshLink();

  // const logoutLink = onError(({ response }) => {
  //   // if (networkError.statusCode === 401) {
  //   //   throw new Error("Missing authentication for this call, you should be logged out!");
  //   //   logout();
  //   // }
  // });

  if (typeof window !== "undefined") {
    return ApolloLink.from([
      tokenRefreshLink,
      // logoutLink,
      authLink,
      errorLink,
      // Use "getOrCreateWebsocketLink" to init WS lazily
      // otherwise WS connection will be created + used even if using "query"
      ApolloLink.split(operationIsSubscription, getOrCreateWebsocketLink, httpLink),
    ]);
  }
  return ApolloLink.from([tokenRefreshLink, authLink, httpLink]);
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createLink(),
    cache: new InMemoryCache({
      resultCaching: true,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "none",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "none",
      },
      mutate: {
        onQueryUpdated: delayRefetchedQuery,
        errorPolicy: "none",
      },
    },
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
