import i18n from "i18next";
// import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";

import LanguageDetector from "i18next-browser-languagedetector";
const isDevelopmentEnv = import.meta.env.DEV;

const locizeOptions = {
  projectId: "5845c913-0cc0-4968-afc6-2ef20cdda31c",
  apiKey: "01597f65-f860-403e-9a1a-ec379993b7a3", // YOU should not expose your apps API key to production!!!
  referenceLng: "en",
};

const fallbackLanguage = "it";

const projectToken = "ac0dd91249aa4ad096baa7efeac51789"; // YOUR PROJECT TOKEN
const apiKey = "733316DA05da4Ca6C9AFfd20E28d22a9c1735F85FC132302"; // YOUR API KEY

const loadPath = `https://cdn.simplelocalize.io/${projectToken}/_latest/{{lng}}`;
const addMissingTranslationsEndpoint = `https://api.simplelocalize.io/api/v1/translations`;
const missingKeysPushInterval = 1_000; // 10 seconds

let missingKeysRequests: any[] = [];

const missingKeyHandler = (
  languages: readonly string[],
  namespace: string,
  key: string,
  fallbackValue: string
) => {
  missingKeysRequests.push({
    key,
    // namespace: namespace, // uncomment if you use namespaces
    language: fallbackLanguage,
    text: fallbackValue,
  });
};

const pushMissingKeys = async () => {
  const isProductionCode = !import.meta.env.DEV;

  if (isProductionCode) {
    return;
  }

  if (missingKeysRequests.length > 0) {
    console.log(`[SimpleLocalize] Pushing missing keys: ${missingKeysRequests.length}`);
    const requestBody = {
      content: missingKeysRequests,
    };
    await fetch(addMissingTranslationsEndpoint, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-SimpleLocalize-Token": apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    missingKeysRequests = [];
  }
};

// @refresh reset
setInterval(() => pushMissingKeys(), missingKeysPushInterval);

export const defaultNS = "common";
export const resources = {
  en: "common",
  fr: "common",
  it: "common",
  de: "common",
  es: "common",
} as const;

const i18nextOptions = {
  // backend: {
  //   loadPath: "/locales/{{lng}}/{{ns}}.json",
  //   addPath: "/locales/add/{{lng}}/{{ns}}.json",
  // },
  debug: false, // debug just for development env.
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  whitelist: ["en", "fr", "it", "de", "es"],
  ns: "common",
  defaultNS,
  react: {
    useSuspense: true,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    // format: (value, format, lng) => { // legacy usage
    //   if (value instanceof Date) {
    //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
    //   }
    //   return value;
    // }
  },
  fallbackLng: fallbackLanguage,
  backend: {
    loadPath,
  },
  saveMissing: !isDevelopmentEnv,
  missingKeyHandler,
};

if (!i18n.isInitialized) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nextOptions);
}

export default i18n;
