import {
  AssetInputCsvImport,
  AssetNode,
  CsvImportCreateAssetMutation,
  useCreateAssetMutation,
  useCsvImportCreateAssetMutation,
} from "graphql/queries/generated/queries";
import { handleMutation, toast } from "middleware/Toaster";
import { useTranslation } from "react-i18next";
import { GraphQLError } from "graphql";
import parseNumber from "helpers/parseFloatFromCsv";
import { useEffect, useState } from "react";
import generateUUID from "helpers/generateUUID";
import useGetOrCreateRegistry from "./useGetOrCreateRegistry";
import useGetOrCreateIrrelevantRegistry from "./useGetOrCreateIrrelevantRegistry";

interface UseCSVRowProcesserProps<T> {
  onComplete: (
    asset: Partial<AssetNode> & { gqlId: string },
    parsedRow: { [key: string]: string | number | null | undefined }
  ) => void;
  onParseError?: (error: unknown) => void;
  // these types need to be unified but to do it we must establish a correct return of parseRow function
  onParseSuccess?: () => void;
  onProcessError?: () => void;
  onProcessSuccess?: (arg0: {
    assets: CsvImportCreateAssetMutation["csvImportCreateAsset"]["importCsvResponse"];
  }) => void;
  fieldsToBeStripped?: [string];
}

export interface DefaultFields {
  title: string;
  author?: string;
  category?: string;
  subcategory?: string;
  creationPeriod?: string;
  technique?: string;
  dimensionsNotes?: string;
  netHeight?: string | number;
  netWidth?: string | number;
  netDepth?: string | number;
  netLengthUnit?: string;
  inventoryNumber?: string;
  assetPresentValue?: {
    amount?: string | number;
    currency?: string;
  };
  assetPresentValueAmount?: string;
  assetPresentValueCurrency?: string;
  shipper?: string;
  lender?: string;
  validated: boolean;
  authorEntityId: string;
}

const useCSVRowProcesser = <T extends DefaultFields>({
  onComplete,
  onParseError,
  onParseSuccess,
  onProcessError,
  onProcessSuccess,
  fieldsToBeStripped,
}: UseCSVRowProcesserProps<T>) => {
  const [seenAuthors, setSeenAuthors] = useState<string[]>([]);
  const [seenRegistries, setSeenRegistries] = useState<string[]>([]);
  const [assetData, setAssetData] = useState<any[]>([]);
  const [parsedRows, setParsedRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const getOrCreateIrrelevant = useGetOrCreateIrrelevantRegistry();
  const getOrCreateRegistry = useGetOrCreateRegistry();
  const [createAsset, { data: createAssetData, error: createAssetError }] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });
  const [createAssetBatch, { data: createAssetBatchData, error: createAssetBatchError }] =
    useCsvImportCreateAssetMutation({
      ...handleMutation("Assets created!"),
    });

  const onProcessErrorDefault = (errors: readonly GraphQLError[], index: number) => {
    const errorsString = errors.map(({ message }) => message).join(", ");
    toast({
      title: t(`Asset at couldn't be uploaded, error at row:`) + index.toString(),
      description: t(`Error: ${errorsString}`),
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onProcessSuccessDefault = () => {};

  const parseCommonFields = async (row: T, index: number) => {
    const clonedRow: T & { authorEntityId: string; lenderId?: string; shipperId?: string } = row;

    if (!row.category) clonedRow.category = "not_validated";
    if (!row.subcategory) clonedRow.subcategory = "";
    if (row.title.trim() === "") {
      toast({
        title: t(`Asset at couldn't be uploaded, error at row:`) + index.toString(),
        description: t(`Error: Asset is missing title, which is a required field.`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      return null;
    }

    delete clonedRow.author;
    Object.keys(clonedRow).forEach((key) => {
      if (typeof row[key] === "string") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        clonedRow[key] = clonedRow[key]?.replace("’", "'");
      }
      return null;
    });
    return clonedRow;
  };

  const parseRow = async ({
    row,
    index,
    author,
    lender,
    shipper,
  }: {
    row: T;
    index: number;
    author: string;
    lender: string;
    shipper: string;
  }) => {
    setIsLoading(true);
    const parsedRow = await parseCommonFields(row, index);
    parsedRow.authorEntityId = author;
    parsedRow.lenderId = lender;
    parsedRow.shipperId = shipper;

    try {
      console.log({ parsedRow });
      if (onParseSuccess) onParseSuccess();
      const strippedRow = {
        ...parsedRow,
        category: "not_validated",
        subcategory: "",
        netWidth:
          typeof parsedRow?.netWidth === "string"
            ? parseFloat(parsedRow?.netWidth)
            : parsedRow?.netWidth,
        netHeight:
          typeof parsedRow?.netHeight === "string"
            ? parseFloat(parsedRow?.netHeight)
            : parsedRow?.netHeight,
        netDepth:
          typeof parsedRow?.netDepth === "string"
            ? parseFloat(parsedRow?.netDepth)
            : parsedRow?.netDepth,
        assetPresentValueAmount: parseNumber(parsedRow?.assetPresentValueAmount),
        assetPresentValueCurrency: parsedRow?.assetPresentValueCurrency || "EUR",
      };
      return strippedRow;
    } catch (error) {
      if (onParseError) onParseError(error);
      setIsLoading(false);
      throw error;
    }
  };

  const constructAssetInput = (data: any, index: number): AssetInputCsvImport => {
    try {
      const mockInput: AssetInputCsvImport = {
        lender: "",
        author: "",
        shipper: "",
        parsedRowIndex: "",
        assetPresentValue: {
          amount: {
            amount: 0,
            currency: "",
          },
          estimateReason: "_CSV_IMPORT",
        },
        asset: {
          authorEntityId: "",
          title: "",
          validated: false,
          category: "",
          subcategory: "",
          creationPeriod: "",
          technique: "",
          dimensionsNotes: "",
          netHeight: 0,
          netWidth: 0,
          netDepth: 0,
          netLengthUnit: "",
          inventoryNumber: "",
        },
      };

      const assetInput = structuredClone(mockInput);

      Object.keys(mockInput.asset).forEach((key) => {
        if (data[key]) {
          assetInput.asset[key] = data[key];
        }
      });

      assetInput.assetPresentValue = {
        amount: {
          amount: data.assetPresentValueAmount || 0,
          currency: String(data.assetPresentValueCurrency) || "EUR",
        },
        estimateReason: "_CSV_IMPORT",
      };

      assetInput.parsedRowIndex = generateUUID();
      return assetInput;
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const processCsvRows = async (rows: T[], { startIndex }: { startIndex: number }) => {
    const authorsToBeCreated: string[] = [];
    const lendersToBeCreated: string[] = [];
    const shippersToBeCreated: string[] = [];

    const createdAuthors = new Map<string, string>();
    const createdLenders = new Map<string, string>();
    const createdShippers = new Map<string, string>();

    rows.map((row) => {
      if (!authorsToBeCreated.includes(row.author)) {
        authorsToBeCreated.push(row.author);
      }
      if (row.lender && !lendersToBeCreated.includes(row.lender))
        lendersToBeCreated.push(row.lender);
      if (row.shipper && !shippersToBeCreated.includes(row.shipper))
        shippersToBeCreated.push(row.shipper);
      return null;
    });

    await Promise.all(
      authorsToBeCreated.map(async (author) => {
        let authorId;
        let createdIrrelevant = false;
        if (!author && !createdIrrelevant) {
          const authorEntity = await getOrCreateIrrelevant();
          authorId = authorEntity.value;
          createdAuthors.set("IRRELEVANT", authorId);
          createdIrrelevant = true;
        } else {
          authorId = await getOrCreateRegistry(author as unknown as string);
          createdAuthors.set(author, authorId);
        }
      })
    );

    await Promise.all(
      lendersToBeCreated.map(async (registry) => {
        const registryId = await getOrCreateRegistry(registry as unknown as string);
        createdLenders.set(registry, registryId);
        return registryId;
      })
    );
    await Promise.all(
      shippersToBeCreated.map(async (registry) => {
        const registryId = await getOrCreateRegistry(registry as unknown as string);
        createdShippers.set(registry, registryId);
        return registryId;
      })
    );

    const strippedEmptyRows = rows.filter((row) => row.title.trim() !== "");

    const assetData = await Promise.all(
      strippedEmptyRows.map(async (row: T, index: number) => {
        const parsedRow = await parseRow({
          row,
          index,
          author: !row.author ? createdAuthors.get("IRRELEVANT") : createdAuthors.get(row.author),
          lender: createdLenders.get(row.lender),
          shipper: createdShippers.get(row.shipper),
        });

        console.log("AFTER", { parsedRow });

        const assetInputData = constructAssetInput(parsedRow, index);
        setParsedRows((prevParsedRows) => [
          ...prevParsedRows,
          { ...parsedRow, parsedRowIndex: assetInputData.parsedRowIndex },
        ]);

        return assetInputData;
      })
    );

    setAssetData((prevAssetData) => [...prevAssetData, ...assetData]);
  };

  useEffect(() => {
    console.log({ assetData });
  }, [assetData]);

  useEffect(() => {
    console.log(seenAuthors.length, seenRegistries.length, seenAuthors, seenRegistries);
  }, [seenAuthors, seenRegistries]);

  const onParsingComplete = async () => {
    const mergedAssetsWithRows: CsvImportCreateAssetMutation["csvImportCreateAsset"]["importCsvResponse"] =
      [];
    console.log({ assetData });
    const { data, errors } = await createAssetBatch({
      variables: {
        input: {
          assetData,
        },
      },
    });

    if (errors) {
      setIsLoading(false);
      onProcessErrorDefault(errors, 1);
      return;
    }
    const { csvImportCreateAsset } = data;

    csvImportCreateAsset?.importCsvResponse?.map(({ asset, parsedRowIndex }) => {
      const syncParsedRow = parsedRows.find((row) => row.parsedRowIndex === parsedRowIndex);
      mergedAssetsWithRows.push({ ...asset, gqlId: asset.id, ...syncParsedRow });
      onComplete({ ...asset, gqlId: asset.id }, syncParsedRow);
    });

    if (onProcessSuccess) onProcessSuccess({ assets: mergedAssetsWithRows });
    else onProcessSuccessDefault();
    setIsLoading(false);
    setAssetData([]);
  };
  return { isLoading, processCsvRows, parseRow, onParsingComplete };
};

export default useCSVRowProcesser;
