import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import BADGE_VARIANTS from "./badgeVariants";
import palette from "./palette";

const brandColors = {
  primaryYellow: "#FFCF21",
  primaryGreen: "#328D2E",
  primaryFuchsia: "#E72B78",
  primaryGrey: "#50535A",
  pastelPrimaryYellow: "#fff9e5",
  pastelPrimaryGreen: "#d6e8d5",
  pastelPrimaryFuchsia: "#F7BFD6",
  brandBlack: "#161616",
  error: "#FF0000",
};

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: "blackAlpha.200", // change the background
  },
  dialog: {
    borderRadius: "xl",
  },
  footer: {
    bg: "gray.200",
    borderBottomRadius: "xl",
  },
});

const modalDanger = definePartsStyle({
  footer: {
    bg: "red.50",
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { danger: modalDanger },
});

const theme = extendTheme({
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
  },
  styles: {
    global: () => ({
      h1: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
        // fontWeight: " !important",
      },
      a: {
        // textDecoration: "underline 2px rgba(0, 0, 0, 0) !important",
        // transition: "text-decoration-color 300ms !important",
        _hover: {
          // textDecorationColor: "rgba(0, 0, 0, 1) !important",
          color: "brand.600",
        },
      },
      h2: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
      },
      h3: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
      },
      h4: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
      },
      h5: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
      },
      h6: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
      },
      body: {
        // bg: "gray.50",
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
        letterSpacing: "-0.5px !important",
      },
      p: {
        marginBottom: "0",
      },
      input: {
        // bg: "white !important",
        borderRadius: "md !important",
      },
      select: {
        // bg: "white !important",
        borderRadius: "md !important",
      },
      table: {
        // boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
        // borderColor: "gray.200",
        // borderLeftWidth: "1px",
        // borderRightWidth: "1px",
        // backgroundColor: "white",
        // borderRadius: "1rem",
        // color: "black",
      },
      td: {
        width: "1px !important",
        whiteSpace: "nowrap !important",
      },
      th: {
        fontSize: "0.7rem !important",
        fontWeight: "500 !important",
      },
    }),
  },
  colors: {
    ...brandColors,
    cardBg: {
      50: "#edf2f796",
    },
    brand: {
      50: "#fdf4f8",
      100: "#FCE9F1",
      200: "#FAD4E4",
      300: "#F7BFD6",
      400: "#F5AAC9",
      500: "#F395BB",
      600: "#e72b78",
      700: "#EE6AA0",
      800: "#EB5593",
      900: "#e72b78",
    },
    black: "#161616",
  },
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Steps,
    Modal: modalTheme,
    Portal: {
      defaultProps: {
        zIndex: 9999,
      },
    },
    Alert: {
      baseStyle: ({ status }: { status: string }) => ({
        container: {
          borderRadius: "lg",
          borderWidth: "1px",
          borderColor: "gray.200",
        },
        icon: {},
      }),
      variants: {
        info: {
          backgroundColor: "blue.50",
          borderColor: "blue.200",
        },
      },
    },
    Badge: {
      baseStyle: {
        backgroundColor: "pastelPrimaryFuchsia",
        fontWeight: 600,
        padding: "0.2rem 0.8rem",
        borderRadius: "xl",
      },
      defaultProps: {
        colorScheme: "brand",
      },
      variants: BADGE_VARIANTS,
    },
    Box: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    Stack: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    HStack: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    VStack: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    Divider: {
      defaultProps: {
        colorScheme: "white",
      },
      baseStyle: {
        borderColor: "gray.100",
        borderWidth: "2px",
      },
    },
    Text: {
      baseStyle: {
        color: "black",
        marginTop: "0 !important",
      },
      variants: {
        label: {
          color: "gray.700",
          fontWeight: "500",
          fontSize: "0.9rem",
        },
        muted: {
          color: "gray.400",
          fontWeight: "500",
          fontSize: "0.9rem",
        },
        tooltip: {
          textDecoration: "underline",
          textDecorationStyle: "dashed",
        },
        danger: {
          color: "red.500",
          fontWeight: "semibold",
        },
        subtitle: {
          color: "gray.600",
          fontSize: "0.95rem",
          fontWeight: "400",
        },
      },
    },
    Kbd: {
      baseStyle: {
        backgroundColor: "gray.100",
        color: "gray.700",
        fontSize: "0.6rem",
      },
    },
    Heading: {
      baseStyle: {
        color: "black",
        marginTop: "0 !important",
      },
      variants: {
        muted: {
          color: "gray.500",
          fontWeight: "400",
          marginTop: "0.2rem",
        },
        tooltip: {
          textDecoration: "underline",
          textDecorationStyle: "dashed",
        },
      },
    },

    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "semibold", // Normally, it is "semibold"
        borderRadius: "0.5rem",
        backgroundColor: "white",

        color: "black",
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        ghost: {
          borderColor: "transparent",
          backgroundColor: "transparent",
          borderWidth: "1px",
        },
        danger: {
          backgroundColor: "red.600",
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.200",
          borderWidth: "1px",
          color: "white",
        },
        warn: {
          backgroundColor: "orange.500",
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.200",
          borderWidth: "1px",
          color: "white",
        },
        "with-shadow": {
          boxShadow: "0 0 2px 2px #efdfde",
        },
        // 4. We can override existing variants
        solid: (props: StyleFunctionProps) => ({}),
        // 5. We can add responsive variants
        sm: {
          fontSize: "sm",
        },
        outline: {
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.300",
          borderWidth: "1px",
          backgroundColor: "white",
          color: "gray.900",
          letterSpacing: "0.2px",
          defaultProps: {},
          _hover: {
            border: "1px solid black",
            color: "black",
          },
          _disabled: {
            backgroundColor: "gray.100",
            color: "gray.500",
          },
        },
        success: {
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.300",
          borderWidth: "1px",
          backgroundColor: palette.green,
          color: "#fff",
          defaultProps: {},
        },
        primary: {
          boxShadow: "0px 5px 3px -3px rgb(0 0 0 / 20%)",
          borderColor: "gray.300",
          borderWidth: "1px",
          backgroundColor: "black",
          color: "white",
          _hover: {
            backgroundColor: "gray.800",
          },
          _disabled: {
            backgroundColor: "gray.100",
            color: "gray.500",
          },
          defaultProps: {},
        },
        // primary: {
        //   boxShadow: "0px 5px 3px -3px rgb(0 0 0 / 20%)",
        //   borderColor: "gray.300",
        //   borderWidth: "1px",
        //   backgroundColor: "brand.900",
        //   color: "white",
        //   defaultProps: {},
        // },
        black: {
          boxShadow: "0px 5px 3px -3px rgb(0 0 0 / 20%)",
          borderColor: "gray.300",
          borderWidth: "1px",
          backgroundColor: "black",
          color: "white",
          _hover: {
            backgroundColor: "gray.800",
          },
          _disabled: {
            backgroundColor: "gray.100",
            color: "gray.500",
          },
          defaultProps: {},
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        variant: "outline",
        size: "sm",
      },
    },
    Input: {
      // baseStyle: {
      //   bgColor: "white",
      //   background: "white",
      //   backgroundColor: "white",
      // },
      defaultProps: {
        size: "sm",
        borderRadius: "lg",
        marginTop: 0,
        backgroundColor: "white",
      },
    },
    Select: {
      defaultProps: {
        size: "sm",
        borderRadius: "lg",
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "sm",
        color: "gray.800",
      },
    },
    TableContainer: {
      baseStyle: {
        borderRadius: "1rem",
      },
    },
    Menu: {
      baseStyle: {
        button: {
          backgroundColor: "white",
        },
      },
    },
    ModalFooter: {
      baseStyle: {
        backgroundColor: "gray.2300",
      },
    },
    Link: {
      variants: {
        manual: {
          textDecoration: "underline",
          _hover: {
            color: "brand.500",
          },
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            fontSize: "sm",
            padding: "0 0 0.4rem 0",
            fontWeight: 600,
            color: "gray.600",

            _selected: {
              fontWeight: 600,
              "& > span.chakra-badge": {
                backgroundColor: "primaryFuchsia",
                color: "gray.100",
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
