import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useRef, useState } from "react";

interface GeolocateAddressOptions {
  fetchAll?: boolean;
  dryRun?: boolean;
  forceCountry?: boolean;
}

const defaultOptions: GeolocateAddressOptions = {
  fetchAll: false,
  dryRun: false,
  forceCountry: false,
};

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const useGeolocation = () => {
  const [maps, setMaps] = useState<typeof google.maps | null>(null);
  const [result, setResult] = useState<google.maps.GeocoderAddressComponent[] | null>(null);
  const ref = useRef(null);
  const loader = new Loader({
    apiKey,
    version: "weekly",
    libraries: ["places"],
  });

  useEffect(() => {
    loader
      .load()
      .then((g) => {
        setMaps(g.maps);
      })
      .catch((e) => console.error(e));
  }, []);

  function getDetailsWrapper(
    detailsApi: google.maps.places.PlacesService,
    maps: typeof google.maps,
    place_id: string
  ) {
    return new Promise<google.maps.GeocoderAddressComponent[]>((resolve, reject) => {
      detailsApi.getDetails({ placeId: place_id }, (e) => {
        resolve(e.address_components);
      });
    });
  }

  async function geolocateAddress(
    address: string,
    country?: string,
    options: GeolocateAddressOptions = defaultOptions
  ) {
    console.log({ address, country });
    const userSuppliedCountry = country;
    try {
      if (!maps) {
        console.error("maps is not defined");
        return undefined;
      }

      if (!address || address.trim().length === 0) {
        console.error("address is not defined");
        return undefined;
      }

      if (options.dryRun) return { raw: address };

      const autocomplete = new maps.places.AutocompleteService();
      const results = await autocomplete.getPlacePredictions({
        input: address,
      });

      if (results.predictions.length === 0) {
        return { raw: address, country: options?.forceCountry ? userSuppliedCountry : "" };
      }

      const obj = {
        raw: "",
        streetNumber: "",
        country: options?.forceCountry ? userSuppliedCountry : "",
        state: "",
        locality: "",
        route: "",
        postalCode: "",
        formatted: "",
      };

      // if(options.fetchAll) return results
      const detailsAPI = new maps.places.PlacesService(document.createElement("div"));
      const parsedAddress: google.maps.GeocoderAddressComponent[] = await getDetailsWrapper(
        detailsAPI,
        maps,
        results.predictions[0].place_id
      );

      obj.raw = address;
      obj.formatted = results.predictions[0].description;

      parsedAddress.forEach(({ types, long_name, short_name }) => {
        // if (types.includes("street_number")) {
        //   obj.streetNumber = short_name;
        // }
        // if (types.includes("route")) {
        //   obj.route = short_name;
        // }

        if (
          (options.forceCountry && !userSuppliedCountry && types.includes("country")) ||
          (!options.forceCountry && obj.country !== "" && types.includes("country"))
        ) {
          obj.country = short_name;
        }
        // if (types.includes("administrative_area_level_1")) {
        //   obj.state = short_name;
        // }
        // if (types.includes("locality") || types.includes("postal_town")) {
        //   obj.locality = short_name;
        // }
        // if (types.includes("postal_code")) {
        //   obj.postalCode = short_name;
        //   // if (!obj.raw.includes(short_name)) {
        //   //   obj.raw += `, ${short_name}`;
        //   // }
        // }
      });
      console.log({ obj });
      return obj;
    } catch (e) {
      console.log(e);
      return {
        raw: address,
        country: options?.forceCountry ? userSuppliedCountry : "",
      };
    }
  }

  return [geolocateAddress];
};

export default useGeolocation;
