import { LazyQueryExecFunction } from "@apollo/client";
import {
  Exact,
  GetRegistriesByExactSearchQuery,
  useCreateRegistryMutation,
  useGetRegistriesByExactSearchLazyQuery,
} from "graphql/queries/generated/queries";
import useStagger from "hooks/useStagger";
import { handleMutation } from "middleware/Toaster";
import { useTranslation } from "react-i18next";

const useGetOrCreateRegistry = () => {
  const [BROKEN_searchRegistry] = useGetRegistriesByExactSearchLazyQuery();
  const searchRegistry: LazyQueryExecFunction<
    GetRegistriesByExactSearchQuery,
    Exact<{
      exactSearch: string;
    }>
  > = useStagger(BROKEN_searchRegistry);
  const { t } = useTranslation();
  const [createRegistry] = useCreateRegistryMutation({ ...handleMutation(t("Registry created!")) });

  const doUseGetOrCreateRegistry = async (exactSearch: string, category = "") => {
    if (!exactSearch || exactSearch?.trim() === "") return undefined;

    const { data: searchRegistryData, error: searchRegistryError } = await searchRegistry({
      variables: {
        exactSearch,
      },
    });
    if (!searchRegistryData?.registries || searchRegistryData.registries.length === 0) {
      const { data: createRegistryData, errors: createRegistryError } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: exactSearch,
              isCompany: false,
              categories: category === "author" ? [] : [category],
              isAuthor: category === "author",
            },
          },
        },
      });

      if (createRegistryError) throw new Error(createRegistryError[0]?.message);
      const {
        createRegistry: { registry },
      } = createRegistryData;
      return registry.id;
    }

    const { registries } = searchRegistryData;

    return registries[0].id as string;
  };

  return doUseGetOrCreateRegistry;
};

export default useGetOrCreateRegistry;
