/* eslint-disable import/prefer-default-export */
import { ImporterLocale } from "./ImporterLocale";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const deDE: ImporterLocale = {
  general: {
    goToPreviousStepTooltip: "Zurückgehen",
  },
  fileStep: {
    initialDragDropPrompt:
      "Ziehen Sie die CSV-Datei hierher, oder klicken Sie, um sie von Ihrem Computer auszuwählen",
    activeDragDropPrompt: "Geben Sie die CSV-Datei hier frei...",

    getImportError: (message) => `Fehler beim Import: ${message}`,
    getDataFormatError: (message) => `Bitte überprüfen Sie das Datenformat: ${message}`,
    goBackButton: "Zurückgehen",
    nextButton: "Wählen Sie Spalten",

    rawFileContentsHeading: "Inhalt der hochgeladenen Datei",
    previewImportHeading: "Datenvorschau",
    dataHasHeadersCheckbox: "Kopfzeile in der Datei",
    previewLoadingStatus: "Vorschau laden...",
  },
  fieldsStep: {
    stepSubtitle: "Wählen Sie Spalten",
    requiredFieldsError: "Bitte ordnen Sie alle erforderlichen Spalten zu",
    nextButton: "Importieren",

    dragSourceAreaCaption: "Zu importierende Spalten",
    getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
      `Seite ${currentPage} von ${pageCount}`,
    getDragSourceActiveStatus: (columnCode: string) => `Zuordnung zur Spalte ${columnCode}`,
    nextColumnsTooltip: "Nächste Spalte anzeigen",
    previousColumnsTooltip: "Vorherige Spalte anzeigen",
    clearAssignmentTooltip: "Alle Spaltenzuordnungen löschen",
    selectColumnTooltip: "Wählen Sie eine Spalte zum Zuordnen",
    unselectColumnTooltip: "Spalte deselektieren",

    dragTargetAreaCaption: "Erforderliche Felder",
    getDragTargetOptionalCaption: (field) => `${field} (optional)`,
    getDragTargetRequiredCaption: (field) => `${field} (obligatorisch)`,
    dragTargetPlaceholder: "Ziehen Sie die Spalte hierhin",
    getDragTargetAssignTooltip: (columnCode: string) => `Zuordnung zur Spalte ${columnCode}`,
    dragTargetClearTooltip: "Spaltenzuordnungen löschen",

    columnCardDummyHeader: "Nicht zugeordnetes Feld",
    getColumnCardHeader: (code) => `Column ${code}`,
  },

  progressStep: {
    stepSubtitle: "Importieren",
    uploadMoreButton: "Weitere Daten hochladen",
    finishButton: "Ende",
    statusError: "Fehler beim Laden",
    statusComplete: "Abgeschlossen",
    statusPending: "Laden...",
    processedRowsLabel: "Verarbeitete Zeilen:",
  },
};
