import i18n from "i18n/i18n";
import { toast } from "middleware/Toaster";
import { Location } from "react-router-dom";
import {
  MGMT_ROUTES,
  REGISTRY_ROUTES,
  SETTINGS_ROUTES,
  SPIN_ROUTES,
  USER_ROUTES,
  SLOG_ROUTES,
} from "routes/constants";
interface ModifiedPermissionNode {
  id: string;
  codename: string;
  name: string;
  contentType: {
    model: string;
    id: string;
  };
}
const getPathAction = (pathname: Location["pathname"]) => {
  if (pathname.includes("/new")) return "add";
  if (pathname.includes("/edit")) return "change";
  return "view";
};
const hasPermissionForRoute = (permissions: ModifiedPermissionNode[], permissionNeeded: string) =>
  permissions.find(({ codename }) => codename === permissionNeeded);

const getPermissionForRoute = (
  permissions: ModifiedPermissionNode[],
  pathname: Location["pathname"],
  isSuperuser: boolean,
  pathAction: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const tenantCanUseMGMT = import.meta.env.VITE_FEATURE_MGMT_ENABLED === "true" ?? false;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const tenantCanUseSPIN = import.meta.env.VITE_FEATURE_SPIN_ENABLED === "true" ?? false;
  const tenantCanUseSLOG = import.meta.env.VITE_FEATURE_SLOG_ENABLED === "true" ?? false;

  if (pathname === "/") return true;

  if (pathname.includes(USER_ROUTES.USER)) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_user`);
  }

  if (pathname.includes(REGISTRY_ROUTES.REGISTRY)) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_registry`);
  }

  if (pathname.includes(MGMT_ROUTES.ASSETS) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_asset`);
  }

  if (pathname.includes(MGMT_ROUTES.COLLECTIONS) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_collection`);
  }

  if (pathname.includes(MGMT_ROUTES.EXHIBITIONS) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_exhibition`);
  }

  if (pathname.includes(MGMT_ROUTES.APPRAISAL) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_appraisal`);
  }

  if (pathname.includes(MGMT_ROUTES.RESTORATION) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_restoration`);
  }

  if (pathname.includes(MGMT_ROUTES.CRSR) && tenantCanUseMGMT) {
    return isSuperuser
      ? true
      : hasPermissionForRoute(permissions, `${pathAction}_conditionstatusreport`);
  }

  if (pathname.includes(SPIN_ROUTES.SPIN) && tenantCanUseSPIN) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_policy`);
  }
  if (pathname.includes(SLOG_ROUTES.DEPOSITATIONS) && tenantCanUseSLOG) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_depositation`);
  }
  if (pathname.includes(SLOG_ROUTES.TRANSPORTATIONS) && tenantCanUseSLOG) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_transportation`);
  }
  if (pathname.includes(SLOG_ROUTES.DEPOSITATION_BILLS) && tenantCanUseSLOG) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_depositation`);
  }
  if (pathname.includes(SLOG_ROUTES.TRANSPORTATION_BILLS) && tenantCanUseSLOG) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, `${pathAction}_transportation`);
  }

  if (pathname.includes(SLOG_ROUTES.SLOG) && tenantCanUseSLOG) {
    const hasAnyOfSlogRoutes =
      hasPermissionForRoute(permissions, `${pathAction}_transportation`) ||
      hasPermissionForRoute(permissions, `${pathAction}_depositation`);
    return isSuperuser ? true : hasAnyOfSlogRoutes;
  }
  if (pathname.includes(SETTINGS_ROUTES.SETTINGS) && isSuperuser) {
    return true;
  }

  return false;
};

const canNavigateRoute = (
  permissions: ModifiedPermissionNode[],
  route: Location,
  isSuperuser: boolean
) => {
  if (!permissions) return false;
  const pathAction = getPathAction(route.pathname);
  const viewPermissions = permissions.filter(({ codename }) => codename.includes(pathAction));
  const permissionFound = getPermissionForRoute(
    viewPermissions,
    route.pathname,
    isSuperuser,
    pathAction
  );
  console.log(`[AUTH] Checking permission for ${route.pathname}`, permissionFound, viewPermissions);

  if (permissionFound) return true;
  toast({
    title: i18n?.t(`You dont have permssion to ${pathAction} ${route.pathname} .`),
    description: i18n?.t(""),
    status: "error",
    duration: 9000,
    isClosable: true,
  });

  return false;
};

export default canNavigateRoute;
