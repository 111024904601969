// import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default {
  AVAILABILITY: [
    { value: "UNKNOWN", label: i18n?.t("AssetAvailability.Unknown") },
    { value: "AVAILABLE", label: i18n?.t("AssetAvailability.Available") },
    { value: "UNAVAILABLE", label: i18n?.t("AssetAvailability.Unavailable") },
    { value: "TEMPORARY_UNAVAILABLE", label: i18n?.t("AssetAvailability.Temporary_unavailable") },
  ],
  LENGTH_UNIT: [
    {
      label: "mm",
      value: "mm",
    },
    {
      label: "cm",
      value: "cm",
    },
    {
      label: "m",
      value: "m",
    },
    {
      label: "in",
      value: "in",
    },
    {
      label: "ft",
      value: "ft",
    },
  ],
  WEIGHT_UNIT: [
    {
      label: "g",
      value: "g",
    },
    {
      label: "hg",
      value: "hg",
    },
    {
      label: "kg",
      value: "kg",
    },
    {
      label: "oz",
      value: "oz",
    },
    {
      label: "lb",
      value: "lb",
    },
  ],
  LIGHT_UNIT: [
    { label: "Lux", value: "LUX" },
    { label: i18n?.t("Foot candles"), value: "FOOT_CANDLES" },
  ],
  TEMPERATURE_UNIT: [
    { label: "C°", value: "C" },
    { label: "°F", value: "F" },
  ],
  DIMENSIONS_ACQUISITIONS: [
    { label: i18n?.t("Given"), value: "GIVEN" },
    { label: i18n?.t("Measured"), value: "MEASURED" },
    { label: i18n?.t("Not measured"), value: "NOT_MEASURED" },
  ],
  INJURY_RISK: [
    { label: i18n?.t("InjuryRisk.None"), value: "NONE" },
    { label: i18n?.t("InjuryRisk.Low"), value: "LOW" },
    { label: i18n?.t("InjuryRisk.Average"), value: "AVERAGE" },
    { label: i18n?.t("InjuryRisk.High"), value: "HIGH" },
  ],
  ASSET_VALUES_ACQUISITIONS: [
    { label: i18n?.t("Bought"), value: "BOUGHT" },
    { label: i18n?.t("Received as a gift"), value: "GIFT" },
    { label: i18n?.t("Inherited"), value: "INHERITED" },
    { label: i18n?.t("Other"), value: "OTHER" },
  ],
  ASSET_VALUES_REASONS: [
    { label: i18n?.t("Alienation"), value: "ALIENATION" },
    { label: "CR", value: "CR" },
    { label: i18n?.t("Insurance quote"), value: "INSURANCE_QUOTE" },
    { label: i18n?.t("Asset analysis"), value: "ASSET_ANALYSIS" },
    { label: i18n?.t("Legal dispute"), value: "LEGAL_DISPUTE" },
  ],
  ASSET_DATING_TYPES: [
    { label: i18n?.t("Period"), value: "PERIOD" },
    { label: i18n?.t("Date"), value: "DATE" },
    { label: i18n?.t("Year"), value: "YEAR" },
    { label: i18n?.t("Century"), value: "CENTURY" },
  ],
  ASSET_DATE_WINDOWS: [
    { label: i18n?.t("End"), value: "END" },
    { label: i18n?.t("Half"), value: "HALF" },
    { label: i18n?.t("Begin"), value: "BEGIN" },
    { label: i18n?.t("First half"), value: "FIRST_HALF" },
    { label: i18n?.t("Second half"), value: "SECOND_HALF" },
  ],
  ASSET_DATE_ERAS: [
    {
      label: i18n?.t("Precambrian hadean (4.6 billions B.C. - 4 billions B.C.)"),
      value: "PRECAMBRIAN_HADEAN",
    },
    {
      label: i18n?.t("Precambrian archean (4 billions B.C. - 2.5 billions B.C.)"),
      value: "PRECAMBRIAN_ARCHEAN",
    },
    {
      label: i18n?.t("Precambrian proterozoic (2.5 billions B.C. - 541,000,000 B.C.)"),
      value: "PRECAMBRIAN_PROTEROZOIC",
    },
    { label: i18n?.t("Precambrian (4.6 billions B.C. - 541,000,000 B.C.)"), value: "PRECAMBRIAN" },
    {
      label: i18n?.t("Cambrian paleozoic (541,000,000 B.C. - 485,400,000 B.C.)"),
      value: "CAMBRIAN_PALEOZOIC",
    },
    {
      label: i18n?.t("Ordovician paleozoic (485,400,000 B.C. - 443,800,000 B.C.)"),
      value: "ORDOVICIAN_PALEOZOIC",
    },
    {
      label: i18n?.t("Silurian paleozoic (443,800,000 B.C. - 416,000,000 B.C.)"),
      value: "SILURIAN_PALEOZOIC",
    },
    {
      label: i18n?.t("Paleozoic devonian (416,000,000 B.C. - 359,200,000 B.C.)"),
      value: "PALEOZOIC_DEVONIAN",
    },
    {
      label: i18n?.t("Paleozoic carboniferous (359,200,000 B.C. -  299,000,000 B.C.)"),
      value: "PALEOZOIC_CARBONIFEROUS",
    },
    {
      label: i18n?.t("Paleozoic permian (299,000,000 B.C. -  251,000,000 B.C.)"),
      value: "PALEOZOIC_PERMIAN",
    },
    { label: i18n?.t("Paleozoic (541,000,000 B.C. -  251,000,000 B.C.)"), value: "PALEOZOIC" },
    {
      label: i18n?.t("Triassic mesozoic (252,200,000 B.C. -  201,300,000 B.C.)"),
      value: "TRIASSIC_MESOZOIC",
    },
    {
      label: i18n?.t("Jurassic mesozoic (201,300,000 B.C. -  145,500,000 B.C.)"),
      value: "JURASSIC_MESOZOIC",
    },
    {
      label: i18n?.t("Creataceous mesozoic (145,500,000 B.C. -  65,500,000 B.C.)"),
      value: "CREATACEOUS_MESOZOIC",
    },
    { label: i18n?.t("Mesozoic (252,200,000 B.C. -  65,500,000 B.C.)"), value: "MESOZOIC" },
    {
      label: i18n?.t("Paleogene cenozoic (65,500,000 B.C. -  23,030,000 B.C.)"),
      value: "PALEOGENE_CENOZOIC",
    },
    {
      label: i18n?.t("Cenozoic neogene (23,030,000 B.C. -  2,588,000 B.C.)"),
      value: "CENOZOIC_NEOGENE",
    },
    { label: i18n?.t("Cenozoic (65,500,000 B.C. -  2,588,000 B.C.)"), value: "CENOZOIC" },
    {
      label: i18n?.t("Lower paleolithic (2,588,000 B.C. -  120,000 B.C.)"),
      value: "LOWER_PALEOLITHIC",
    },
    {
      label: i18n?.t("Middle paleolithic (120,000 B.C. -  36,000 B.C.)"),
      value: "MIDDLE_PALEOLITHIC",
    },
    {
      label: i18n?.t("Upper paleolithic (36,000 B.C. -  10,000 B.C.)"),
      value: "UPPER_PALEOLITHIC",
    },
    { label: i18n?.t("Paleolithic (2,588,000 B.C. -  10,000 B.C.)"), value: "PALEOLITHIC" },
    { label: i18n?.t("Mesolithic (10,000 B.C. -  8,000 B.C.)"), value: "MESOLITHIC" },
    { label: i18n?.t("Neolithic (8,000 B.C. -  3,000 B.C.)"), value: "NEOLITHIC" },
    {
      label: i18n?.t("Copper or cuprolytic age (5,000 B.C. -  3,000 B.C.)"),
      value: "COPPER_OR_CUPROLYTIC_AGE",
    },
    { label: i18n?.t("Bronze age (3,000 B.C. - approximately 1,200 B.C.)"), value: "BRONZE_AGE" },
    { label: i18n?.t("Iron age (approximately 1,200 B.C. - 1 B.C.)"), value: "IRON_AGE" },
    { label: i18n?.t("Ancient age (3,500 B.C. - 476 A.C.)"), value: "ANCIENT_AGE" },
    { label: i18n?.t("Medieval age (476 A.C. - 1492 A.C.)"), value: "MEDIEVAL_AGE" },
    { label: i18n?.t("Modern age (1492 - 1789)"), value: "MODERN_AGE" },
  ],

  ASSET_FRAME_MATERIALS: [
    { label: i18n?.t("WOOD"), value: "WOOD" },
    { label: i18n?.t("METAL"), value: "METAL" },
    { label: i18n?.t("PAPER"), value: "PAPER" },
    { label: i18n?.t("PERSPEX"), value: "PERSPEX" },
    { label: i18n?.t("PLASTIC"), value: "PLASTIC" },
    { label: i18n?.t("MULTIMATERIAL"), value: "MULTIMATERIAL" },
    { label: i18n?.t("STONE"), value: "STONE" },
    { label: i18n?.t("LAMINATE"), value: "LAMINATE" },
    { label: i18n?.t("FABRIC"), value: "FABRIC" },
  ],

  FILE_SIZE_UNIT: [
    {
      label: "kb",
      value: "KB",
    },
    {
      label: "mb",
      value: "MB",
    },
    {
      label: "gb",
      value: "GB",
    },
    {
      label: "tb",
      value: "TB",
    },
  ],

  MEDIA_LENGTH_UNIT: [
    {
      label: "s",
      value: "S",
    },
    {
      label: "m",
      value: "M",
    },
    {
      label: "h",
      value: "H",
    },
  ],

  RESOLUTION_UNIT: [
    {
      label: "p",
      value: "P",
    },
    {
      label: "k",
      value: "K",
    },
  ],

  STAMP_PRINTING_TECHNIQUE: [
    { value: "LITHOGRAPHY", label: i18n?.t("Lithography") },
    { value: "OFFSET", label: i18n?.t("Offset") },
    { value: "SERIGRAPHY", label: i18n?.t("Serigraphy") },
    { value: "RELIEF_PRINTING", label: i18n?.t("Relief printing") },
    { value: "LETTERPRESS_PRINTING", label: i18n?.t("Letterpress printing") },
    { value: "CHALCOGRAPHY", label: i18n?.t("Chalcography") },
    { value: "ROTOGRAVURE", label: i18n?.t("Rotogravure") },
    { value: "PHOTOGRAVURE", label: i18n?.t("Photogravure") },
  ],

  STAMP_REALIZATION: [
    { value: "INDENTED", label: i18n?.t("Indented") },
    { value: "NOT_INDENTED", label: i18n?.t("Not indented") },
    { value: "SELF_ADHESIVE", label: i18n?.t("Self-adhesive") },
    { value: "AUTOMATIC", label: i18n?.t("Automatic") },
  ],

  STAMP_INDENTATION_TYPES: [
    { value: "LINEAR", label: i18n?.t("Linear") },
    { value: "COMB", label: i18n?.t("Comb") },
    { value: "BLOCK", label: i18n?.t("Block") },
  ],

  STAMP_CENTERING: [
    { value: "SUPERB", label: i18n?.t("Superb") },
    { value: "EXTRA_FINE", label: i18n?.t("Extra fine") },
    { value: "FINE", label: i18n?.t("Fine") },
    { value: "AVERAGE", label: i18n?.t("Average") },
    { value: "POOR", label: i18n?.t("Poor") },
  ],

  STAMP_FORMATS: [
    { value: "SHEETS", label: i18n?.t("Sheets") },
    { value: "COIL", label: i18n?.t("Coil") },
    { value: "BOOKLET", label: i18n?.t("Booklet") },
  ],

  PHILATELIC_CONSERVATION: [
    { value: "MNH", label: i18n?.t("Mint never-hinged") },
    { value: "MLH", label: i18n?.t("Mint lightly hinged") },
    { value: "MVLH", label: i18n?.t("Mint very lightly hinged") },
    { value: "MHH", label: i18n?.t("Mint heavily hinged") },
    { value: "HR", label: i18n?.t("Hinge remaining") },
    { value: "OG", label: i18n?.t("Original gum") },
    { value: "NG", label: i18n?.t("No gum") },
    { value: "RG", label: i18n?.t("Regummed") },
    { value: "FC", label: i18n?.t("First day cover") },
  ],

  NUMISMATIC_CONSERVATION_USA: [
    { value: "PO1", label: "PO 1" },
    { value: "FA2", label: "FA 2" },
    { value: "AG3", label: "AG 3" },
    { value: "G4", label: "G 4" },
    { value: "G6", label: "G 6" },
    { value: "VG8", label: "VG 8" },
    { value: "VG10", label: "VG 10" },
    { value: "F12", label: "F 12" },
    { value: "F15", label: "F 15" },
    { value: "VF20", label: "VF 20" },
    { value: "VF25", label: "VF 25" },
    { value: "VF30", label: "VF 30" },
    { value: "VF35", label: "VF 35" },
    { value: "XF40", label: "XF 40" },
    { value: "XF45", label: "XF 45" },
    { value: "AU50", label: "AU 50" },
    { value: "AU53", label: "AU 53" },
    { value: "AU55", label: "AU 55" },
    { value: "AU58", label: "AU 58" },
    { value: "MS60", label: "MS 60" },
    { value: "MS61", label: "MS 61" },
    { value: "MS62", label: "MS 62" },
    { value: "MS63", label: "MS 63" },
    { value: "MS64", label: "MS 64" },
    { value: "MS65", label: "MS 65" },
    { value: "MS66", label: "MS 66" },
    { value: "MS67", label: "MS 67" },
    { value: "MS68", label: "MS 68" },
    { value: "MS69", label: "MS 69" },
    { value: "MS70", label: "MS 70" },
  ],
  NUMISMATIC_CONSERVATION_ITA: [
    { value: "M", label: "M - Mediocre" },
    { value: "D", label: "D - Discreto" },
    { value: "B", label: "B - Bello" },
    { value: "MB", label: "MB - Molto bello" },
    { value: "BB", label: "BB - Bellissimo" },
    { value: "SPL", label: "SPL - Splendido" },
    { value: "SUP", label: "SUP - Superbo" },
    { value: "FDC_FDS", label: "FDC/FDS - Fior di conio" },
  ],

  NUMISMATIC_CONSERVATION_ENG: [
    { value: "PR_F", label: "PR_F - Poor fair" },
    { value: "AG", label: "AG - About good" },
    { value: "G", label: "G - Good" },
    { value: "VG", label: "VG - Very good" },
    { value: "F", label: "F - Fine" },
    { value: "VF", label: "VF - Very fine" },
    { value: "EF_XF", label: "EF/XF - Extremely fine" },
    { value: "AU", label: "AU - About uncirculated" },
    { value: "UNC", label: "UNC - Uncirculated" },
  ],

  NUMISMATIC_CONSERVATION_FRA: [
    { value: "M", label: "M - Mediocre" },
    { value: "BC", label: "BC - Bien conservée" },
    { value: "AB", label: "AB - Assenz beau" },
    { value: "B", label: "B - Beau" },
    { value: "TB", label: "TB - Tres beau" },
    { value: "TTB", label: "TTB - Tres tres beau" },
    { value: "SUP", label: "SUP - Superbe" },
    { value: "SPL", label: "SPL - Splendid" },
    { value: "NEUF", label: "NEUF - Neuf" },
    { value: "FDC", label: "FDC - Fleur de coin" },
  ],

  NUMISMATIC_CONSERVATION_SPA: [
    { value: "MC", label: "MC - Mal conservada" },
    { value: "RC", label: "RC - Regular conservada" },
    { value: "BC", label: "BC - Bien conservada" },
    { value: "MBC", label: "MBC - Muy bien conservada" },
    { value: "EBC", label: "EBC - Extraordinariamente bien conservada" },
    { value: "SC", label: "SC - Sin circular" },
  ],

  NUMISMATIC_CONSERVATION_GER: [
    { value: "GES", label: "GES - Gering enhalten schiecht" },
    { value: "GE", label: "GE - Gut erhalten" },
    { value: "SGE", label: "SGE - Ser gut erhalter" },
    { value: "S", label: "S - Schon" },
    { value: "SS", label: "SS - Ser schon" },
    { value: "VZ", label: "VZ - Vorzuglich" },
    { value: "ST_BFR", label: "ST/BFR - Stempelglanz bankfrisch" },
  ],

  NUMISMATIC_RARITY_DEGREE: [
    { value: "NC", label: i18n?.t("NC - Not common") },
    { value: "CC", label: i18n?.t("CC - Very common") },
    { value: "C", label: i18n?.t("C - Common") },
    { value: "U", label: i18n?.t("U - Unique") },
    { value: "R6", label: i18n?.t("R6 - Unique survivor") },
    { value: "R5", label: i18n?.t("R5 - Few known") },
    { value: "R4", label: i18n?.t("R4 - Extremely rare") },
    { value: "R3", label: i18n?.t("R3 - Very very rare") },
    { value: "R2", label: i18n?.t("R2 - Very rare") },
    { value: "R", label: i18n?.t("R - Rare") },
  ],

  PHILATELIC_RARITY_DEGREE: [
    { value: "C2", label: i18n?.t("Very_common") },
    { value: "C1", label: i18n?.t("More than 1000 known exemplars") },
    { value: "C", label: i18n?.t("Up to 1000 known exemplars") },
    { value: "R", label: i18n?.t("Up to 500 known exemplars") },
    { value: "R1", label: i18n?.t("Up to 250 known exemplars") },
    { value: "R2", label: i18n?.t("Up to 100 known exemplars") },
    { value: "R3", label: i18n?.t("Up to 50 known exemplars") },
    { value: "R4", label: i18n?.t("Up to 25 known exemplars") },
    { value: "R5", label: i18n?.t("Up to 5 known exemplars") },
    { value: "R6", label: i18n?.t("Probably unique") },
  ],

  MEDIA_TYPE_DOCUMENT_POSITION: 0, // questo serve per individuare in modo sicuro la posizione della voce document nel sottostante MEDIA_TYPES

  MEDIA_TYPES: [
    { value: "DOCUMENT", label: i18n?.t("Document") },
    { value: "IMAGE", label: i18n?.t("Image") },
    { value: "AUDIO_VIDEO", label: i18n?.t("Audio/Video") },
    { value: "FINGERPRINT", label: i18n?.t("Fingerprint") },
  ],

  IMAGE_TYPES: [
    { value: "FRONT", label: i18n?.t("Front") },
    { value: "REAR", label: i18n?.t("Rear") },
    { value: "FRONT_DETAIL", label: i18n?.t("Front detail") },
    { value: "REAR_DETAIL", label: i18n?.t("Rear detail") },
    { value: "OTHER", label: i18n?.t("Other") },
  ],

  MATRIX_TYPES: [
    { value: "FRONT", label: i18n?.t("Front") },
    { value: "REAR", label: i18n?.t("Rear") },
    { value: "FRONT_DETAIL", label: i18n?.t("Front detail") },
    { value: "REAR_DETAIL", label: i18n?.t("Rear detail") },
    { value: "THREE_D", label: "3D" },
  ],

  ASSET_DOCUMENT_TYPES: [
    { value: "AUTHENTICITY_CERTIFICATE", label: i18n?.t("Authenticity certificate") },
    { value: "ARCHIVING_CERTIFICATE", label: i18n?.t("Archiving certificate") },
    { value: "FINE_ARTS_ISSUE", label: i18n?.t("Fine arts issue") },
    { value: "CUSTOM_DOCUMENT", label: i18n?.t("Custom document") },
    { value: "CATALOG", label: i18n?.t("Catalog") },
    { value: "CONTRACT", label: i18n?.t("Contract") },
    { value: "PUBLICATION", label: i18n?.t("Publication") },
    { value: "OTHER", label: i18n?.t("Other") },
  ],

  ASSET_DOCUMENT_COA_TYPES: [
    { value: "AUTHENTICITY_CERTIFICATE", label: i18n?.t("Authenticity certificate") },
    { value: "COA_SIMILAR", label: i18n?.t("COA Similar") },
  ],

  FINE_ARTS_ISSUE_TYPES: [
    {
      value: "SELF_CERTIFICATION_CONTEMPORARY_ART",
      label: i18n?.t("Self-certification of contemporary art (AAC)"),
    },
    {
      value: "TEMPORARY_CIRCULATION_CERTIFICATE",
      label: i18n?.t("Temporary circulation certificate (ACT)"),
    },
    { value: "FREE_CIRCULATION_CERTIFICATE", label: i18n?.t("Free circulation certificate (ALC)") },
    {
      value: "CERTIFICATE_SUCCESSFUL_IMPORT",
      label: i18n?.t("Certificate of successful import (CAI)"),
    },
    { value: "CERTIFICATE_SHIPMENT", label: i18n?.t("Certificate of shipment (CAS)") },
    { value: "STATEMENT_50_70", label: i18n?.t("Statement 50-70") },
    { value: "STATEMENT_VAL", label: i18n?.t("Statement VAL") },
    { value: "DEFINITIVE_EXPORT_LICENSE", label: i18n?.t("Definitive export license (LCD)") },
    { value: "TEMPORARY_EXPORT_LICENSE", label: i18n?.t("Temporary export license (LCT)") },
    { value: "RENEWAL_CAI", label: i18n?.t("Renewal CAI") },
    { value: "RENEWAL_CAS", label: i18n?.t("Renewal CAS") },
    { value: "EXPIRATION_CAI", label: i18n?.t("Expiration CAI") },
    { value: "EXPIRATION_CAS", label: i18n?.t("Expiration CAS") },
    { value: "OTHER_EXPORT_LICENSES", label: i18n?.t("Other export licenses") },
  ],

  CATEGORY: [
    { value: "sculpture", label: i18n?.t("Sculpture") },
    { value: "picture", label: i18n?.t("Picture") },
    { value: "print_or_photo", label: i18n?.t("Print or photo") },
    { value: "urban", label: i18n?.t("Urban art") },
    { value: "installation", label: i18n?.t("Installation") },
    { value: "digital", label: i18n?.t("Digital") },
    { value: "design_or_antique", label: i18n?.t("Design or antique") },
    { value: "archeology", label: i18n?.t("Archeology") },
    { value: "naturalistic_goods", label: i18n?.t("Naturalistic goods") },
    { value: "numismatic_coins", label: i18n?.t("Numismatic, coins and medals") },
    { value: "numismatic_banknotes", label: i18n?.t("Numismatic and banknotes") },
    { value: "philately", label: i18n?.t("Philately") },
    { value: "books", label: i18n?.t("Books") },
    { value: "not_validated", label: "---" },
    { value: "other", label: i18n?.t("Other") },
  ],
  SUBCATEGORY: [
    {
      value: "sculpture",
      label: i18n?.t("sculpture"),
      options: [{ value: "sculpture", label: i18n?.t("Sculpture") }],
    },
    {
      value: "picture",
      label: i18n?.t("Picture"),
      options: [
        { value: "painting", label: i18n?.t("Painting") },
        { value: "drawing", label: i18n?.t("Drawing") },
        { value: "artist_proof", label: i18n?.t("Artist Proof") },
        { value: "work_on_paper", label: i18n?.t("Work on Paper") },
        { value: "tapestries", label: i18n?.t("Tapestries") },
        { value: "other_bidimensional_works", label: i18n?.t("Other Bidimensional Works") },
      ],
    },
    {
      value: "print_or_photo",
      label: i18n?.t("Print or photo"),
      options: [
        { value: "photography", label: i18n?.t("Photography") },
        { value: "graphic", label: i18n?.t("Graphic") },
        { value: "prints", label: i18n?.t("Prints") },
        { value: "lithography", label: i18n?.t("Lithography") },
        { value: "serigraphy", label: i18n?.t("Serigraphy") },
        { value: "xylography", label: i18n?.t("Xylography") },
      ],
    },
    {
      value: "urban",
      label: i18n?.t("Urban"),
      options: [{ value: "urban", label: i18n?.t("Urban art") }],
    },
    {
      value: "installation",
      label: i18n?.t("Installation"),
      options: [{ value: "installation", label: i18n?.t("Installation") }],
    },
    {
      value: "digital",
      label: i18n?.t("Digital work"),
      options: [
        { value: "video", label: i18n?.t("Video") },
        { value: "audio", label: i18n?.t("Audio") },
        { value: "static_work", label: i18n?.t("Static work") },
        { value: "dynamic_work", label: i18n?.t("Dynamic work") },
      ],
    },
    {
      value: "design_or_antique",
      label: i18n?.t("Design or antique"),
      options: [{ value: "design_or_antique", label: i18n?.t("Design or antique") }],
    },
    {
      value: "archeology",
      label: i18n?.t("Archeology"),
      options: [{ value: "archeology", label: i18n?.t("Archeology") }],
    },
    {
      value: "naturalistic_goods",
      label: i18n?.t("Naturalistic goods"),
      options: [{ value: "naturalistic_goods", label: i18n?.t("Naturalistic goods") }],
    },
    {
      value: "numismatic_coins",
      label: i18n?.t("Numismatic, coins and medals"),
      options: [{ value: "numismatic_coins", label: i18n?.t("Numismatic, coins and medals") }],
    },
    {
      value: "numismatic_banknotes",
      label: i18n?.t("Numismatic and banknotes"),
      options: [{ value: "numismatic_banknotes", label: i18n?.t("Numismatic and banknotes") }],
    },
    {
      value: "philately",
      label: i18n?.t("Philately"),
      options: [{ value: "philately", label: i18n?.t("Philately") }],
    },
    {
      value: "books",
      label: i18n?.t("Books"),
      options: [{ value: "books", label: i18n?.t("Books") }],
    },
    {
      value: "other",
      label: i18n?.t("Other"),
      options: [
        { value: "automobile", label: i18n?.t("Automobile") },
        { value: "jewelry", label: i18n?.t("Jewelry") },
        { value: "wine", label: i18n?.t("Wine") },
        { value: "watches", label: i18n?.t("Watches") },
        { value: "clothes", label: i18n?.t("Clothes") },
        { value: "musical_instruments", label: i18n?.t("Musical instruments") },
        { value: "toys", label: i18n?.t("Toys") },
        { value: "historical_objects", label: i18n?.t("Historical objects") },
        { value: "memorabilia", label: i18n?.t("Memorabilia") },
        { value: "other", label: i18n?.t("Other") },
      ],
    },
    { value: "not_validated", label: "---", options: [{ value: "not_validated", label: "---" }] },
  ],
};
