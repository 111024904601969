import palette from "./palette";

export default {
  INTERNAL: {
    backgroundColor: palette.lightGreen,
    color: palette.green,
    borderColor: palette.green,
    borderWidth: 1,
  },
  EXTERNAL: {
    colorScheme: "purple",
    backgroundColor: "purple.100",
    borderColor: "purple",
    borderWidth: 1,
  },
  EXPIRED: {
    backgroundColor: palette.lightRed,
    color: palette.red,
    borderColor: palette.red,
    borderWidth: 1,
  },
  CURRENT: {
    backgroundColor: palette.lightPrimary,
    color: palette.primary,
    borderColor: palette.primary,
    borderWidth: 1,
  },
  PENDING: {
    backgroundColor: palette.lightOrange,
    color: palette.orange,
    borderColor: palette.orange,
    borderWidth: 1,
  },
  APPROVED: {
    backgroundColor: palette.lightGreen,
    color: palette.green,
    borderColor: palette.green,
    borderWidth: 1,
  },
  PERMANENT_COLLECTION: {
    backgroundColor: palette.lightYellow,
    color: palette.orange,
    borderColor: palette.orange,
    borderWidth: 1,
  },
  TEMPORARY_EXHIBITION: {
    backgroundColor: palette.lightEmerald,
    color: palette.emerald,
    borderColor: palette.emerald,
    borderWidth: 1,
  },
  NAIL_TO_NAIL: {
    backgroundColor: "#d2eed5",
    color: "#4ebc59",
    borderColor: "#4ebc59",
    borderWidth: 1,
  },
  STAY_RISK: {
    backgroundColor: "#d0e2f1",
    color: "#438bc7",
    borderColor: "#438bc7",
    borderWidth: 1,
  },
  TRAVEL_RISK: {
    backgroundColor: "#f7bfd6",
    color: "#e72371",
    borderColor: "#e72371",
    borderWidth: 1,
  },
  ALL_RISK: {
    backgroundColor: "#cfe9f2",
    color: "#3fa7ca",
    borderColor: "#3fa7ca",
    borderWidth: 1,
  },
  FIRST_RISK: {
    backgroundColor: "#e3d9e7",
    color: "#9168a1",
    borderColor: "#9168a1",
    borderWidth: 1,
  },
  AGREED_VALUE: {
    backgroundColor: "#E6FAC7",
    color: "#77BC10",
    borderColor: "#77BC10",
    borderWidth: 1,
  },
  DECLARED_VALUE: {
    backgroundColor: "#F2D3BA",
    color: "#2F9D50",
    borderColor: "#2F9D50",
    borderWidth: 1,
  },
  AGREED_AND_DECLARED_VALUE: {
    backgroundColor: "#D0F1DA",
    color: "#2A8D48",
    borderColor: "#2A8D48",
    borderWidth: 1,
  },
  SCULPTURE: {
    backgroundColor: "#F7FAFC",
    color: "#718096",
    borderColor: "#718096",
    borderWidth: 1,
  },
  BASIC: {
    backgroundColor: "#F7FAFC",
    color: "#718096",
    borderColor: "#718096",
    borderWidth: 1,
  },
  PRO: {
    backgroundColor: "#F7FAFC",
    color: "#718096",
    borderColor: "#718096",
    borderWidth: 1,
  },
  ENTERPRISE: {
    backgroundColor: "#F7FAFC",
    color: "#718096",
    borderColor: "#718096",
    borderWidth: 1,
  },
  GENERALI: {
    backgroundColor: "#F7FAFC",
    color: "#718096",
    borderColor: "#718096",
    borderWidth: 1,
  },
  ADD_POLICY_ASSET: {
    backgroundColor: "#F7FAFC",
    color: "#718096",
    borderColor: "#718096",
    borderWidth: 1,
  },
  UPDATE_POLICY_ASSET: {
    backgroundColor: palette.lightOrange,
    color: palette.orange,
    borderColor: palette.orange,
    borderWidth: 1,
  },
  DELETE_POLICY_ASSET: {
    backgroundColor: palette.lightRed,
    color: palette.red,
    borderColor: palette.red,
    borderWidth: 1,
  },
};
