import { ApolloQueryResult, LazyQueryExecFunction } from "@apollo/client";
import { FilterSet } from "contexts/FilterContext";
import { Exact } from "graphql/queries/generated/queries";
import getColumns, { CustomColumn } from "helpers/getColumns";
import { debounce, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSessionStorage from "./useSessionStorage";

interface BasicVariables {
  first: number;
  offset: number;
  [key: string]: any;
}

interface UseTableConfigurationProps<TQuery> {
  // data: any[];
  model: string;
  customFields?: string[];
  customColumns?: CustomColumn<TQuery>[];
  loadData?: LazyQueryExecFunction<
    TQuery,
    Exact<{
      first?: number;
      after?: string;
      offset?: number;
      [key: string]: any;
    }>
  >;
  refetch?: (
    variables?: Partial<
      Exact<{
        first?: number;
        after?: string;
        offset?: number;
        validated?: boolean;
        createdByUserGroup?: string;
        orderBy?: string;
        search?: string;
      }>
    >
  ) => Promise<ApolloQueryResult<TQuery>>;
  filters?: any;
  // totalCount?: number;
}

const useTableConfiguration = <TQuery, TVariables extends BasicVariables>({
  model,
  // data,
  customFields,
  customColumns,
  loadData,
  filters,
  refetch,
}: // totalCount,
UseTableConfigurationProps<TQuery>) => {
  const [previousVariables, setPreviousVariables] = useSessionStorage("currentParameters", {});
  const fetchIdRef = useRef(0);
  const [columns, setColumns] = useState<any[]>([]);
  const [serializedFilters, setSerializedFilters] = useState<Record<string, string>>({});
  const [pageSize, setPageSize] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [data, setData] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const computedColumns = useMemo(
    () =>
      data
        ? getColumns({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            data: data[model]?.edges[0]?.node,
            customFields,
            customColumns,
          })
        : [],
    [data]
  );
  useEffect(() => {
    const columns = computedColumns;
    setColumns(columns);
  }, [data]);

  const serializeFilters = (selectedFilters: FilterSet) => {
    const filters = {};
    Object.keys(selectedFilters).forEach((filter) => {
      if (filter === "search") {
        filters[filter] = selectedFilters[filter].value;
      }

      if (selectedFilters[filter].type === "boolean") {
        filters[filter] = selectedFilters[filter].value;
        return;
      }

      filters[filter] = selectedFilters[filter].value;
    });

    return filters;
  };

  // useEffect(() => {
  //   console.log({ serializedFilters });
  // }, [serializedFilters]);

  const fetchData = useCallback(
    debounce(
      async (
        pageSize: number,
        pageIndex: number,
        filters: FilterSet,
        serializedSorts: { orderBy: string } | Record<string, never> = {},
        fetchAllData = false
      ) => {
        // eslint-disable-next-line no-plusplus
        const fetchId = ++fetchIdRef.current;

        const initialOffset = pageIndex * pageSize;

        if (fetchId === fetchIdRef.current) {
          const newSerializedFilters: Record<string, string> = serializeFilters(filters);

          if (isEqual(serializedFilters, newSerializedFilters)) return;

          // if (
          //   newSerializedFilters.search !== "" &&
          //   pageIndex > 0 &&
          //   previousVariables?.search !== newSerializedFilters.search
          // ) {
          //   initialOffset = 0;
          // }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          const { data } = await loadData({
            variables: {
              first: pageSize,
              offset: initialOffset,
              ...newSerializedFilters,
              ...serializedSorts,
            } as unknown as TVariables,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
          });
          setPreviousVariables({
            first: pageSize,
            offset: initialOffset,
            ...newSerializedFilters,
            ...serializedSorts,
          } as unknown as TVariables);
          setData((prevData: any) => data);
          setSerializedFilters(newSerializedFilters);

          if (newSerializedFilters) setPageSize(pageSize);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          if (!data || !Object.hasOwn(data as object, model)) {
            return;
          }
          setTotalCount(data[model]?.totalCount);
        }
      },
      1000
    ),
    [filters]
  );

  const paginatedRefetch = async () => {
    const { data } = await refetch();
    setData(data);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-unsafe-optional-chaining
  const pageCount = data ? Math.ceil(totalCount / pageSize) : 0;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return {
    data,
    columns,
    fetchData,
    totalCount,
    pageCount,
    paginatedRefetch,
    fetchAllData: debounce(loadData, 1000),
  };
};

export default useTableConfiguration;
