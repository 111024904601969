interface parseDateOptions {
  locale?: string;
  onlyDate?: boolean;
  dateStyle?: "short" | "medium" | "long" | "full";
  timeStyle?: string;
}

type parseDateType = (date: string, options?: parseDateOptions) => string | undefined;

const parseDate: parseDateType = (date, options) => {
  const locale = options?.locale ?? "it-IT";
  const onlyDate = options?.onlyDate ?? false;
  const dateStyle = options?.dateStyle ?? "short";
  if (!date) return null;
  if (onlyDate) {
    return (
      date &&
      new Date(date).toLocaleDateString(locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
    );
  }

  return (
    date &&
    new Date(date).toLocaleString(locale, {
      dateStyle,
      timeStyle: "short",
    })
  );
};
export default parseDate;
