/* eslint-disable import/prefer-default-export */
import { ImporterLocale } from "./ImporterLocale";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const enUS: ImporterLocale = {
  general: {
    goToPreviousStepTooltip: "Go back",
  },
  fileStep: {
    initialDragDropPrompt: "Drag the CSV file here, or click to select it from your computer",
    activeDragDropPrompt: "Release the CSV file here...",

    getImportError: (message) => `Error during import: ${message}`,
    getDataFormatError: (message) => `Please check the data format: ${message}`,
    goBackButton: "Go back",
    nextButton: "Select columns",

    rawFileContentsHeading: "Content of the uploaded file",
    previewImportHeading: "Data preview",
    dataHasHeadersCheckbox: "Header present in the file",
    previewLoadingStatus: "Loading preview...",
  },
  fieldsStep: {
    stepSubtitle: "Select columns",
    requiredFieldsError: "Please assign all required columns",
    nextButton: "Import",

    dragSourceAreaCaption: "Columns to import",
    getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
      `Page ${currentPage} of ${pageCount}`,
    getDragSourceActiveStatus: (columnCode: string) => `Assignment to column ${columnCode}`,
    nextColumnsTooltip: "Show next column",
    previousColumnsTooltip: "Show previous column",
    clearAssignmentTooltip: "Delete all column assignments",
    selectColumnTooltip: "Select a column to assign",
    unselectColumnTooltip: "Deselect column",

    dragTargetAreaCaption: "Required fields",
    getDragTargetOptionalCaption: (field) => `${field} (optional)`,
    getDragTargetRequiredCaption: (field) => `${field} (mandatory)`,
    dragTargetPlaceholder: "Drag the column here",
    getDragTargetAssignTooltip: (columnCode: string) => `Assignment to column ${columnCode}`,
    dragTargetClearTooltip: "Delete column assignments",

    columnCardDummyHeader: "Unassigned field",
    getColumnCardHeader: (code) => `Column ${code}`,
  },

  progressStep: {
    stepSubtitle: "Import",
    uploadMoreButton: "Upload more data",
    finishButton: "End",
    statusError: "Loading error",
    statusComplete: "Completed",
    statusPending: "Loading...",
    processedRowsLabel: "Processed rows:",
  },
};
