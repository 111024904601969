import getColumns from "helpers/getColumns";

const serializeSorts = (
  columnsMap: Map<string, ReturnType<typeof getColumns> & { sortByAccessor?: string }>,
  sortBy: [{ id: string; desc: boolean }] | [] = []
) => {
  if (sortBy?.length === 0 || columnsMap.size === 0) return { orderBy: "-created_at" };

  if (!columnsMap?.has(sortBy[0]?.id)) return { orderBy: "-created_at" };
  const columnConfig = columnsMap.get(sortBy[0].id);

  const hasDifferentAccessor = (() => columnConfig?.sortByAccessor ?? sortBy[0].id)();
  return { orderBy: `${sortBy[0]?.desc ? "-" : ""}${hasDifferentAccessor}` };
};

export default serializeSorts;
